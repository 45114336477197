<template>

  <div>
    <b-card
      v-if="showFilterHeader"
    >

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataUserType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterUserTypeOptions"
            :clearable="false"
            :placeholder="'Select Type'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterStatusOptions"
            :clearable="false"
            :placeholder="'Select Status'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center">
            <b-button
              class="ml-1"
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>

      </b-row>

    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <template
        v-if="showTableNameInHeader"
        #header
      >
        <h4
          class="d-flex align-items-center mb-0"
        >
          Tasks
        </h4>

        <feather-icon
          v-if="useCollapse"
          :aria-expanded="showOverviewTable ? 'true' : 'false'"
          :icon="showOverviewTable ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="20"
          class="align-middle text-body"
          aria-controls="collapse-app-tasks-table"
          @click="showOverviewTable = !showOverviewTable"
        />
      </template>

      <b-collapse
        id="collapse-app-tasks-table"
        v-model="showOverviewTable"
      >
        <div
          v-if="showTableHeader"
          class="table-header mx-2 mb-2"
          :class="showTableNameInHeader ? '' : 'mt-2'"
        >

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              v-if="useBulkActions"
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-form-checkbox
                v-model="elementsPerPageCheckbox"
                class="mr-1 bulk-checkbox"
                @change="markElementsOnPage"
              />

              <label class="mr-1 mb-0">Show</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :value="perPage"
                :clearable="false"
                :reduce="option => option.value"
                class="per-page-selector d-inline-block ml-50 mr-1"
                @input="updatePerPage"
              />
            </b-col>

            <b-col
              v-else
              cols="12"
              md="6"
            />

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <label class="mr-1 mb-0">Search</label>
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search"
                  class="w-25"
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refApplicationTasksListTable"
          :items="fetchApplicationTasksList"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          sticky-header="75vh"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative table-sticky-head"
        >

          <!-- Loader -->
          <template #table-busy>
            <TableSpinner />
          </template>

          <template
            v-if="useBulkActions"
            #cell(selected)="data"
          >
            <b-form-checkbox
              v-if="useBulkActions"
              v-model="markedElements"
              :value="data.item.id"
              inline
              @change="handleElementsPerPageCheckbox"
            />
          </template>

          <!-- Column: task_name -->
          <template #cell(task)="data">
            <b-badge
              variant="light-primary"
              v-if="canUpdateApplicationTask"
              v-b-modal.edit-application-task
              @click="manageableAppTaskId = data.item.id"
            >
              {{ data.item.task_name }}
            </b-badge>
            <b-badge
              variant="light-primary"
              v-else
            >
              {{ data.item.task_name }}
            </b-badge>
          </template>

          <!-- Column: user_name -->
          <template #cell(task_name)="data">
            <b-link
              v-if="data.item.status === 'Completed'"
              :to="{ name: 'admin-task-edit', params: { id: data.item.id } }"
            >{{ data.item.task_name }}
            </b-link>
            <span v-else>{{ data.item.task_name }}</span>
          </template>

          <!-- Column: user_name -->
          <template #cell(user_name)="data">
            <b-link
              :to="{ name: 'admin-application-edit', params: { id: data.item.application_id, type: getApplicationUserType(data.item.camper_id) } }"
            >{{ data.item.user_name }}
            </b-link>
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge
              :variant="`light-${resolveStatusVariant(data.item.status)}`"
            >
              {{ data.item.status }}
            </b-badge>
          </template>

          <!-- Column: due_date -->
          <template #cell(date)="data">
            <span>{{ data.item.date | shortDate }}</span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="mx-auto p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="canUpdateApplicationTask"
                v-b-modal.edit-application-task
                @click="manageableAppTaskId = data.item.id"
              >
                <span>Edit Task</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canDeleteApplicationTask && data.item.status !== 'Manual Deleted'"
                @click="openDeleteConfirmation(data.item.id, data.item.user_name)"
              >
                <span>Delete</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-if="totalItems && perPage !== 'all'"
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>

          <b-row
            v-if="useBulkActions"
          >
            <b-col
              cols="12"
              class="d-flex align-items-center mt-2"
            >
              <b-dropdown
                id="dropdown-buttons"
                text="Bulk Actions"
                variant="outline-danger"
              >
                <b-dropdown-item
                  v-b-modal.bulk-add-to-group-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Add To Group
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canSendBulkCommunication"
                  v-b-modal.bulk-communication-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Communication
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.bulk-adding-charge-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Charge
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.bulk-generate-letter-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Generate Letter
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.bulk-tag-assign-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Tag Assign
                </b-dropdown-item>
              </b-dropdown>
            </b-col>

          </b-row>
        </div>
      </b-collapse>

    </b-card>

    <application-task-edit-modal
      v-if="manageableAppTaskId"
      :application-task-id="manageableAppTaskId"
      @updated="updateTable"
    />

    <bulk-communicate-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :total-elements-count="markedElements.length"
      :filter-name="'application_task_id'"
      @successfullySend="bulkCommunicateSuccess"
    />

    <bulk-add-to-group-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_task_id'"
      @successfullySend="bulkAddToGroupSuccess"
    />

    <bulk-adding-charge-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_task_id'"
      @success="bulkCreateChargeSuccess"
    />

    <bulk-generate-letter-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_task_id'"
      @success="bulkGenerateLetterSuccess"
    />

    <bulk-tag-assign-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_task_id'"
      :program-id="programId"
      @success="bulkTagAssignSuccess"
    />

    <confirmation-modal
      v-if="manageableAppTaskId"
      :toggle-modal="confirmationModalToggle"
      :type="'-delete-application-task'"
      :title="'Are you sure'"
      :message="`Are you sure you want to delete this application task for application(${manageableAppTaskName})? All related field results and PPA for this task will be lost`"
      :is-loading="isLoading"
      @close-modal="confirmationModalToggle = !confirmationModalToggle"
      @confirm="deleteItem"
    />
  </div>

</template>

<script>
import { filters } from '@core/mixins/filters'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  BBadge,
  BLink,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormCheckbox,
  BCollapse,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Vue from 'vue'
import ApplicationTaskEditModal from '@/views/admin/tasks/application-task-list/ApplicationTaskEditModal.vue'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import BulkCommunicateModal from '@/views/components/bulk-communicate-modal/BulkCommunicateModal.vue'
import BulkAddToGroupModal from '@/views/components/bulk-add-to-group/BulkAddToGroupModal.vue'
import BulkAddingChargeModal from '@/views/components/adding-charge-modal/BulkAddingChargeModal.vue'
import BulkGenerateLetterModal from '@/views/components/bulk-generate-letter/BulkGenerateLetterModal.vue'
import BulkTagAssignModal from '@/views/components/bulk-tag-assign/BulkTagAssignModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import { appApplicationTasksList } from '@/store/modules/app-application-tasks-list'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'

let debounceTimeout = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'ApplicationTaskList',
  components: {
    BCollapse,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormCheckbox,
    vSelect,

    ApplicationTaskEditModal,
    TableSpinner,

    BulkCommunicateModal,
    BulkAddToGroupModal,
    BulkAddingChargeModal,
    BulkGenerateLetterModal,
    BulkTagAssignModal,
    ConfirmationModal,
  },
  mixins: [filters],

  props: {
    showTableNameInHeader: {
      type: Boolean,
      default: false,
    },
    showFilterHeader: {
      type: Boolean,
      default: true,
    },
    showTableHeader: {
      type: Boolean,
      default: true,
    },
    useBulkActions: {
      type: Boolean,
      default: true,
    },
    useCollapse: {
      type: Boolean,
      default: false,
    },
    filterName: {
      type: String,
      default: null,
    },
    filterValue: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      confirmationModalToggle: false,
      showOverviewTable: true,
      permissionSubjects,
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
    canSendBulkEmailCommunication() {
      return this.$can('send', permissionSubjects.BulkEmailCommunication)
    },
    canSendBulkSmsCommunication() {
      return this.$can('send', permissionSubjects.BulkSMSCommunication)
    },
    canSendBulkCommunication() {
      return this.canSendBulkEmailCommunication || this.canSendBulkSmsCommunication
    },
    canDeleteApplicationTask() {
      return this.$can('delete', permissionSubjects.UserTasks)
    },
    canUpdateApplicationTask() {
      return this.$can('update', permissionSubjects.UserTasks)
    },
    tableColumns() {
      return appApplicationTasksList.getters.getTableColumns
    },
    perPage() {
      return appApplicationTasksList.getters.getPerPage
    },
    totalItems() {
      return appApplicationTasksList.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appApplicationTasksList.getters.getCurrentPage
      },
      set(val) {
        appApplicationTasksList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appApplicationTasksList.getters.getPerPageOptions
    },
    statusesColors() {
      return appApplicationTasksList.getters.getStatusesColors
    },
    searchQuery: {
      get() {
        return appApplicationTasksList.getters.getSearchQuery
      },
      set(val) {
        appApplicationTasksList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    sortBy() {
      return appApplicationTasksList.getters.getSortBy
    },
    isSortDirDesc() {
      return appApplicationTasksList.getters.getIsSortDirDesc
    },
    filterStatusOptions() {
      return appApplicationTasksList.getters.getFilterStatusOptions
    },
    filterUserTypeOptions() {
      return appApplicationTasksList.getters.getFilterUserTypeOptions
    },
    isLoading() {
      return appApplicationTasksList.getters.getIsLoading
    },
    dataMeta() {
      return appApplicationTasksList.getters.getDataMeta
    },
    filterDataStatus: {
      get() {
        return appApplicationTasksList.getters.getFilterDataStatus
      },
      set(val) {
        appApplicationTasksList.mutations.SET_FILTER_DATA_STATUS(val)
      },
    },
    filterDataUserType: {
      get() {
        return appApplicationTasksList.getters.getFilterDataUserType
      },
      set(val) {
        appApplicationTasksList.mutations.SET_FILTER_DATA_USER_TYPE(val)
      },
    },
    manageableAppTaskName: {
      get() {
        return appApplicationTasksList.getters.getManageableAppTaskName
      },
      set(val) {
        appApplicationTasksList.mutations.SET_MANAGEABLE_APP_TASK_NAME(val)
      },
    },
    manageableAppTaskId: {
      get() {
        return appApplicationTasksList.getters.getManageableAppTaskId
      },
      set(val) {
        appApplicationTasksList.mutations.SET_MANAGEABLE_APP_TASK_ID(val)
      },
    },
    markedElements: {
      get() {
        return appApplicationTasksList.getters.getMarkedElements
      },
      set(val) {
        appApplicationTasksList.mutations.SET_MARKED_ELEMENTS(val)
      },
    },
    elementsPerPageCheckbox: {
      get() {
        return appApplicationTasksList.getters.getElementsPerPageCheckbox
      },
      set(val) {
        appApplicationTasksList.mutations.SET_ELEMENTS_PER_PAGE_CHECKBOX(val)
      },
    },
  },
  watch: {
    dataMeta() {
      this.handleElementsPerPageCheckbox()
    },
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }

      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
    filterDataStatus(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterDataStatus', val)
      }
    },
    filterDataUserType(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterDataUserType', val)
      }
    },
  },
  async created() {
    this.setFilterParams()

    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    getApplicationUserType(camperId) {
      return camperId !== null ? this.camperStudent(1) : 'Staff'
    },
    updatePerPage(val) {
      appApplicationTasksList.mutations.SET_PER_PAGE(val)
    },
    clearFilters() {
      appApplicationTasksList.mutations.CLEAR_FILTERS()
    },
    setFilterParams() {
      appApplicationTasksList.mutations.SET_FILTER_PARAMS()
    },
    fetchApplicationTasksList() {
      return appApplicationTasksList.actions.fetchApplicationTasksList({
        name: this.filterName,
        value: this.filterValue,
      })
    },
    resolveStatusVariant(status) {
      return this.statusesColors[status] ?? 'primary'
    },
    updateTable() {
      this.$refs.refApplicationTasksListTable.refresh()
    },
    openDeleteConfirmation(id, userName) {
      this.manageableAppTaskId = id
      this.manageableAppTaskName = userName
      this.$refs['confirmation-delete-application-task'].show()
    },
    async deleteItem() {
      this.isLoading = true
      await appApplicationTasksList.actions.deleteApplicationTask(this.manageableAppTaskId)
      this.isLoading = false
      this.$refs['confirmation-delete-application-task'].hide()
      this.updateTable()
    },
    markElementsOnPage(val) {
      if (val) {
        this.$refs.refApplicationTasksListTable.localItems.forEach(task => {
          const index = this.markedElements.indexOf(task.id)
          if (index < 0) {
            this.markedElements.push(task.id)
          }
        })
      } else {
        this.$refs.refApplicationTasksListTable.localItems.forEach(task => {
          const index = this.markedElements.indexOf(task.id)
          if (index >= 0) {
            this.markedElements.splice(index, 1)
          }
        })
      }
    },
    handleElementsPerPageCheckbox() {
      let counter = 0
      this.$refs.refApplicationTasksListTable.localItems.forEach(task => {
        if (this.markedElements.indexOf(task.id) !== -1) {
          counter += 1
        }
      })

      this.elementsPerPageCheckbox = (this.$refs.refApplicationTasksListTable.localItems.length && counter === this.$refs.refApplicationTasksListTable.localItems.length)
    },
    async bulkCommunicateSuccess() {
      this.$refs['bulk-communication-modal'].hide()
      await this.clearData()
    },
    async bulkAddToGroupSuccess() {
      this.$refs['bulk-add-to-group-modal'].hide()
      await this.clearData()
    },
    async bulkCreateChargeSuccess() {
      this.$refs['bulk-adding-charge-modal'].hide()
      await this.clearData()
    },
    async bulkGenerateLetterSuccess() {
      this.$refs['bulk-generate-letter-modal'].hide()
      await this.clearData()
    },
    async bulkTagAssignSuccess() {
      this.$refs['bulk-tag-assign-modal'].hide()
      await this.clearData()
    },
    async clearData() {
      this.markedElements = []
      this.elementsPerPageCheckbox = false
      await this.updateTable()
    },
  },
})
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>
