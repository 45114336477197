import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { ApplicationTaskListState, IApplicationTasksListRequest, IApplicationTasksListFilterObject } from './types'
import {
  fetchApplicationTasksList,
  updateApplicationTask,
} from '@/api/applicationTasks'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

// eslint-disable-next-line import/prefer-default-export
export const appApplicationTasksList = createModule(
  'appApplicationTasksList',
  {
    namespaced: true,
    state: {
      tableColumns: [
        { key: 'selected', label: 'selected', sortable: false },
        { key: 'task', label: 'task name', sortable: false },
        { key: 'user_name', label: 'user', sortable: false },
        { key: 'status', label: 'status', sortable: true },
        { key: 'date', label: 'due date', sortable: false },
        { key: 'actions', label: 'actions', sortable: false },
      ],
      perPage: Number(localStorage.getItem('applicationTasksPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '' as string | (string | null)[],
      sortBy: 'created_at',
      isSortDirDesc: true,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      manageableAppTaskId: null,
      manageableAppTaskName: null,
      isLoading: false,
      filterStatusOptions: ['To Do', 'Completed', 'Overdue'],
      filterUserTypeOptions: ['Student', 'Staff'],
      filterDataUserType: '',
      filterDataStatus: '',
      markedElements: [],
      elementsPerPageCheckbox: false,
      statusesColors: {
        Completed: 'success',
        Overdue: 'danger',
        'Manual Deleted': 'warning',
      },
    } as ApplicationTaskListState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getDataMeta: state => state.dataMeta,
      getManageableAppTaskId: state => state.manageableAppTaskId,
      getManageableAppTaskName: state => state.manageableAppTaskName,
      getIsLoading: state => state.isLoading,
      getFilterStatusOptions: state => state.filterStatusOptions,
      getFilterUserTypeOptions: state => state.filterUserTypeOptions,
      getFilterDataStatus: state => state.filterDataStatus,
      getFilterDataUserType: state => state.filterDataUserType,
      getMarkedElements: state => state.markedElements,
      getElementsPerPageCheckbox: state => state.elementsPerPageCheckbox,
      getStatusesColors: state => state.statusesColors,
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('applicationTasksPerPage', val.toString())
        state.perPage = val
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      CLEAR_FILTERS(state) {
        state.filterDataStatus = ''
        state.filterDataUserType = ''
        state.searchQuery = ''
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('applicationTasksPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
        state.filterDataStatus = query.filterDataStatus || ''
        state.filterDataUserType = query.filterDataUserType || ''
      },
      SET_MANAGEABLE_APP_TASK_ID: (state, val) => {
        state.manageableAppTaskId = val
      },
      SET_MANAGEABLE_APP_TASK_NAME: (state, val) => {
        state.manageableAppTaskName = val
      },
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_FILTER_DATA_STATUS(state, val: string) {
        state.filterDataStatus = val
      },
      SET_FILTER_DATA_USER_TYPE(state, val: string) {
        state.filterDataUserType = val
      },
      SET_MARKED_ELEMENTS(state, val: []) {
        state.markedElements = val
      },
      SET_ELEMENTS_PER_PAGE_CHECKBOX(state, val: boolean) {
        state.elementsPerPageCheckbox = val
      },
    },
    actions: {
      async fetchApplicationTasksList({ state }, filter: IApplicationTasksListFilterObject) {
        try {
          const queryParams: IApplicationTasksListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            sortBy: state.sortBy,
            sortDesc: state.isSortDirDesc,
          }
          if (state.searchQuery) queryParams.userName = state.searchQuery
          if (state.filterDataStatus) queryParams.status = state.filterDataStatus
          if (state.filterDataUserType) queryParams.userRole = state.filterDataUserType

          if (router.currentRoute.name === 'application-tasks') {
            queryParams.taskId = router.currentRoute.params.taskId || null
          }
          if (filter.name === 'parentId') {
            queryParams.parentId = filter.value
          }
          if (filter.name === 'familyId') {
            queryParams.familyId = filter.value
          }
          if (filter.name === 'applicationId') {
            queryParams.applicationId = filter.value
          }

          const response:any = await fetchApplicationTasksList(queryParams)

          const {
            total, from, to,
          } = response.data.meta

          const dataMeta = {
            from,
            to,
            of: total,
          }

          appApplicationTasksList.mutations.SET_TOTAL_ITEMS(total)
          appApplicationTasksList.mutations.SET_DATA_META(dataMeta)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching letters' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async deleteApplicationTask(ctx, id) {
        try {
          const queryParams = {
            application_task_id: id,
            status: 'Manual Deleted',
          }
          await updateApplicationTask(queryParams)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success manual deleting Application Task',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting Application Task',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
    },
  },
)

appApplicationTasksList.register(store)
